// Toggle header menu
$('.menu-toggle').on('click', function(e){
    e.preventDefault();
    var menu = $(this).attr('aria-expanded');
    if (menu == 'false') {
        $(this).attr('aria-expanded', 'true');
        $(this).find('.fi').removeClass('fi-icon-menu').addClass('fi-icon-menu-close');
        $(this).next('ul').addClass('show');
    } else {
        $(this).attr('aria-expanded', 'false');
        $(this).find('.fi').removeClass('fi-icon-menu-close').addClass('fi-icon-menu');
        $(this).next('ul').removeClass('show');
    }
});
