// Tabs
$('.c-tabs ul li a').on('click', function(e) {
    e.preventDefault();

    $('.c-tabs ul li a').removeClass('active');

    var $active = $(this);
    $active.addClass('active');

    var $active_tabs = $(this).data('name');

    $('.c-tabs-content > div').removeClass('active');
    $('.c-tabs-content > div#' + $active_tabs).addClass('active');
});
