// Modal
$('a[rel="ajax:modal"]').click(function(event) {

    var url = $(this).attr("href");

    $.get(url, function (resp) {
        var div = $('<div></div>')
            .append('<img class="modal-image" src="'+url+'">')
            .appendTo('body');

        div.modal({
            modalClass: "modal modal-image"
        });

    });

    return false;
});
