var themeSaved = localStorage.getItem('theme');

if (themeSaved !== null) {
    if (themeSaved == 'dark') {
        $('.theme button').attr('data-switch', 'dark');
        $('.theme button .fi').removeClass('fi-icon-theme-light-off').addClass('fi-icon-theme-light-on');
        $('body').addClass('theme-dark');
    }
}

$('.theme button').on('click', function(e) {
    e.preventDefault();
    var theme = $(this).attr('data-switch');
    if (theme == 'dark') {
        $(this).attr('data-switch', 'light');
        $('.theme button .fi').removeClass('fi-icon-theme-light-on').addClass('fi-icon-theme-light-off');
        localStorage.setItem('theme', 'light');
    } else {
        $(this).attr('data-switch', 'dark');
        $('.theme button .fi').removeClass('fi-icon-theme-light-off').addClass('fi-icon-theme-light-on');
        localStorage.setItem('theme', 'dark');
    }
    $('body').toggleClass('theme-dark');
});
